import React from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Heart, Bell, Menu, LogOut, User, LogIn, UserPlus, Map as MapIcon, Clock } from 'lucide-react';
import { useStore } from '../store/useStore';
import { useAuth } from '../hooks/useAuth';
import { Logo } from './Logo';
import { Button } from './shared/Button';
import { Container } from './shared/Container';
import { cn } from '../utils/cn';
import { AuthModal } from './AuthModal';

export function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isAuthenticated, user } = useStore();
  const { signOut } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [showAuthModal, setShowAuthModal] = React.useState(false);
  const [authMode, setAuthMode] = React.useState<'login' | 'register'>('login');

  const navigation = React.useMemo(() => 
    isAuthenticated ? [
      { name: 'Carte', href: '/carte', icon: MapIcon },
      { name: 'Horaires', href: '/horaires', icon: Clock },
      { name: 'Favoris', href: '/favoris', icon: Heart },
      { name: 'Notifications', href: '/notifications', icon: Bell },
    ] : [],
  [isAuthenticated]);

  React.useEffect(() => {
    const authParam = searchParams.get('auth');
    if (authParam === 'login' || authParam === 'register') {
      setAuthMode(authParam);
      setShowAuthModal(true);
    }
  }, [searchParams]);

  const handleSignOut = React.useCallback(async () => {
    try {
      await signOut();
      setIsMenuOpen(false);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }, [signOut]);

  const handleShowAuth = (mode: 'login' | 'register') => {
    setAuthMode(mode);
    setShowAuthModal(true);
    setIsMenuOpen(false);
  };

  return (
    <>
      <nav className="sticky top-0 z-50 bg-white/80 backdrop-blur-sm border-b border-gray-200">
        <Container>
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Link to="/" className="flex items-center" onClick={() => setIsMenuOpen(false)}>
                <Logo />
              </Link>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-4">
              {navigation.map((item) => {
                const Icon = item.icon;
                const isActive = location.pathname === item.href;
                return (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={cn(
                      'nav-link',
                      isActive ? 'nav-link-active' : 'nav-link-inactive'
                    )}
                  >
                    <Icon className="w-4 h-4 mr-2" />
                    {item.name}
                  </Link>
                );
              })}

              {isAuthenticated ? (
                <div className="flex items-center ml-4 pl-4 border-l border-gray-200">
                  <Link
                    to="/parametres"
                    className="flex items-center mr-4 text-gray-600 hover:text-blue-600 transition-colors"
                  >
                    <User className="w-4 h-4 mr-2" />
                    <span className="text-sm font-medium">
                      {user?.username}
                    </span>
                  </Link>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={handleSignOut}
                    className="text-gray-600 hover:text-red-600"
                  >
                    <LogOut className="w-4 h-4" />
                  </Button>
                </div>
              ) : (
                <div className="flex items-center space-x-2">
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => handleShowAuth('login')}
                    className="flex items-center gap-2"
                  >
                    <LogIn className="w-4 h-4" />
                    Se connecter
                  </Button>
                  <Button
                    variant="gradient"
                    size="sm"
                    onClick={() => handleShowAuth('register')}
                    className="flex items-center gap-2"
                  >
                    <UserPlus className="w-4 h-4" />
                    S'inscrire
                  </Button>
                </div>
              )}
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden flex items-center">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="p-2"
                aria-label="Menu"
              >
                <Menu className="w-6 h-6" />
              </Button>
            </div>
          </div>

          {/* Mobile Navigation */}
          {isMenuOpen && (
            <div className="md:hidden animate-fade-in pb-4">
              <div className="space-y-2">
                {navigation.map((item) => {
                  const Icon = item.icon;
                  const isActive = location.pathname === item.href;
                  return (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={cn(
                        'nav-link',
                        isActive ? 'nav-link-active' : 'nav-link-inactive'
                      )}
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <Icon className="w-4 h-4 mr-2" />
                      {item.name}
                    </Link>
                  );
                })}

                {isAuthenticated ? (
                  <>
                    <Link
                      to="/parametres"
                      className="nav-link nav-link-inactive"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <User className="w-4 h-4 mr-2" />
                      Paramètres
                    </Link>
                    <Button
                      variant="ghost"
                      fullWidth
                      onClick={handleSignOut}
                      className="justify-start text-red-600 hover:bg-red-50"
                    >
                      <LogOut className="w-4 h-4 mr-2" />
                      Déconnexion
                    </Button>
                  </>
                ) : (
                  <div className="space-y-2 p-2">
                    <Button
                      variant="secondary"
                      fullWidth
                      onClick={() => handleShowAuth('login')}
                      className="justify-center"
                    >
                      <LogIn className="w-4 h-4 mr-2" />
                      Se connecter
                    </Button>
                    <Button
                      variant="gradient"
                      fullWidth
                      onClick={() => handleShowAuth('register')}
                      className="justify-center"
                    >
                      <UserPlus className="w-4 h-4 mr-2" />
                      S'inscrire
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </Container>
      </nav>

      {showAuthModal && !isAuthenticated && (
        <AuthModal
          onClose={() => {
            setShowAuthModal(false);
            navigate(location.pathname, { replace: true });
          }}
          initialMode={authMode}
        />
      )}
    </>
  );
}