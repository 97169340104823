import React, { useState } from 'react';
import { X, AlertCircle, Mail, Lock, User } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { getRandomQuestions } from '../data/authQuestions';
import { Button } from './shared/Button';
import { Input } from './shared/Input';
import { Alert } from './shared/Alert';

interface AuthModalProps {
  onClose: () => void;
  initialMode?: 'login' | 'register';
}

export function AuthModal({ onClose, initialMode = 'login' }: AuthModalProps) {
  const [mode, setMode] = useState<'login' | 'register'>(initialMode);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { signIn, signUp } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      if (mode === 'register') {
        if (!email || !username || !password) {
          throw new Error('Veuillez remplir tous les champs');
        }
        if (!email.includes('@')) {
          throw new Error('Email invalide');
        }
        if (password.length < 6) {
          throw new Error('Le mot de passe doit contenir au moins 6 caractères');
        }
        const result = await signUp(email, password, username);
        setSuccess(result.message);
        setTimeout(onClose, 2000);
      } else {
        await signIn(email, password);
        onClose();
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Une erreur est survenue');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-2xl p-6 max-w-md w-full animate-slide-up">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-900">
            {mode === 'login' ? 'Connexion' : 'Inscription'}
          </h2>
          <Button
            variant="ghost"
            size="sm"
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600"
          >
            <X className="w-6 h-6" />
          </Button>
        </div>

        {error && (
          <Alert type="error" className="mb-4">
            {error}
          </Alert>
        )}

        {success && (
          <Alert type="success" className="mb-4">
            {success}
          </Alert>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          {mode === 'register' && (
            <Input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Votre nom d'utilisateur"
              label="Nom d'utilisateur"
              icon={<User className="w-4 h-4" />}
              autoComplete="username"
            />
          )}
          
          <Input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="votre@email.com"
            label="Email"
            icon={<Mail className="w-4 h-4" />}
            autoComplete="email"
          />

          <Input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="••••••••"
            label="Mot de passe"
            icon={<Lock className="w-4 h-4" />}
            autoComplete={mode === 'login' ? 'current-password' : 'new-password'}
          />

          <div className="flex flex-col gap-3 pt-2">
            <Button
              type="submit"
              variant="gradient"
              isLoading={loading}
              fullWidth
            >
              {mode === 'login' ? 'Se connecter' : 'S\'inscrire'}
            </Button>
            <Button
              type="button"
              variant="ghost"
              onClick={() => setMode(mode === 'login' ? 'register' : 'login')}
              className="text-sm text-gray-600 hover:text-blue-600"
              disabled={loading}
            >
              {mode === 'login'
                ? "Pas encore de compte ? S'inscrire"
                : 'Déjà un compte ? Se connecter'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}