import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { synagogues } from '../data/synagogues';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';

const icon = new Icon({
  iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

export function Map() {
  return (
    <MapContainer
      center={[43.2965, 5.3698]}
      zoom={13}
      className="h-[calc(100vh-16rem)] w-full rounded-lg shadow-md"
      style={{ minHeight: '400px' }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {synagogues.map((synagogue) => (
        <Marker
          key={synagogue.id}
          position={[synagogue.coordinates.lat, synagogue.coordinates.lng]}
          icon={icon}
        >
          <Popup>
            <div className="p-2 min-w-[200px]">
              <h3 className="font-semibold text-lg mb-1">{synagogue.name}</h3>
              <p className="text-sm text-gray-600 mb-2">{synagogue.address}</p>
              {synagogue.phone && (
                <p className="text-sm text-blue-600 mb-2">
                  <a href={`tel:${synagogue.phone}`}>{synagogue.phone}</a>
                </p>
              )}
              <div className="text-sm">
                <p className="font-semibold mb-1">Shacharit:</p>
                <p className="mb-2">{synagogue.prayerTimes.shacharit.join(', ')}</p>
                <p className="font-semibold mb-1">Minha:</p>
                <p className="mb-2">{synagogue.prayerTimes.mincha.join(', ')}</p>
                <p className="font-semibold mb-1">Arvit:</p>
                <p>{synagogue.prayerTimes.arvit.join(', ')}</p>
              </div>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}