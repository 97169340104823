import React from 'react';
import { MapPin, Bell, Heart } from 'lucide-react';
import { Card } from '../components/shared/Card';
import { Container } from '../components/shared/Container';

export function Home() {
  return (
    <Container>
      <div className="min-h-[calc(100vh-16rem)] flex flex-col justify-center">
        <div className="text-center mb-16">
          <h1 className="heading-primary mb-6 text-5xl leading-tight">
            Guide des Synagogues de Marseille
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto leading-relaxed">
            Trouvez facilement les horaires de prières et restez connecté à votre communauté.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto w-full">
          <Card className="p-8 text-center hover:shadow-lg transition-shadow duration-200">
            <div className="bg-blue-50 w-16 h-16 rounded-2xl flex items-center justify-center mx-auto mb-6">
              <MapPin className="w-8 h-8 text-blue-600" />
            </div>
            <h2 className="text-xl font-semibold mb-3">Localisation Facile</h2>
            <p className="text-gray-600 leading-relaxed">
              Trouvez les synagogues les plus proches de vous avec notre carte interactive
            </p>
          </Card>

          <Card className="p-8 text-center hover:shadow-lg transition-shadow duration-200">
            <div className="bg-blue-50 w-16 h-16 rounded-2xl flex items-center justify-center mx-auto mb-6">
              <Bell className="w-8 h-8 text-blue-600" />
            </div>
            <h2 className="text-xl font-semibold mb-3">Notifications</h2>
            <p className="text-gray-600 leading-relaxed">
              Recevez des rappels pour ne jamais manquer un office
            </p>
          </Card>

          <Card className="p-8 text-center hover:shadow-lg transition-shadow duration-200">
            <div className="bg-blue-50 w-16 h-16 rounded-2xl flex items-center justify-center mx-auto mb-6">
              <Heart className="w-8 h-8 text-blue-600" />
            </div>
            <h2 className="text-xl font-semibold mb-3">Favoris</h2>
            <p className="text-gray-600 leading-relaxed">
              Enregistrez vos synagogues préférées pour un accès rapide
            </p>
          </Card>
        </div>
      </div>
    </Container>
  );
}