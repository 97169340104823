import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './Container';

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white/80 backdrop-blur-sm border-t mt-auto py-6">
      <Container>
        <div className="flex justify-center space-x-6">
          <Link to="/mentions-legales" className="text-gray-600 hover:text-blue-600">
            Mentions légales
          </Link>
          <Link to="/confidentialite" className="text-gray-600 hover:text-blue-600">
            Politique de confidentialité
          </Link>
          <Link to="/conditions" className="text-gray-600 hover:text-blue-600">
            Conditions d'utilisation
          </Link>
          <Link to="/contact" className="text-gray-600 hover:text-blue-600">
            Contact
          </Link>
        </div>
        
        <div className="mt-4 text-center">
          <p className="text-sm text-gray-600">
            © {currentYear} Tefilati - Tous droits réservés
          </p>
        </div>
      </Container>
    </footer>
  );
}