import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
import { Map } from './pages/Map';
import { Schedule } from './pages/Schedule';
import { Favorites } from './pages/Favorites';
import { Notifications } from './pages/Notifications';
import { Settings } from './pages/Settings';
import { Contact } from './pages/Contact';
import { LegalNotice } from './pages/static/LegalNotice';
import { PrivacyPolicy } from './pages/static/PrivacyPolicy';
import { Terms } from './pages/static/Terms';

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="carte" element={<Map />} />
        <Route path="horaires" element={<Schedule />} />
        <Route path="favoris" element={<Favorites />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="parametres" element={<Settings />} />
        <Route path="contact" element={<Contact />} />
        
        {/* Static pages */}
        <Route path="mentions-legales" element={<LegalNotice />} />
        <Route path="confidentialite" element={<PrivacyPolicy />} />
        <Route path="conditions" element={<Terms />} />
      </Route>
    </Routes>
  );
}