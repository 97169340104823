import { supabase } from '../lib/supabase';
import type { User, Synagogue } from '../types';

// Users
export async function createUser(userData: {
  username: string;
  email: string;
  password: string;
  favorites?: string[];
  notificationPreferences?: User['notificationPreferences'];
}): Promise<User> {
  const { data: authData, error: authError } = await supabase.auth.signUp({
    email: userData.email,
    password: userData.password,
  });

  if (authError) throw authError;
  if (!authData.user) throw new Error('Failed to create user');

  const { data, error } = await supabase
    .from('users')
    .insert({
      id: authData.user.id,
      username: userData.username,
      email: userData.email,
      favorites: userData.favorites || [],
      notification_preferences: userData.notificationPreferences || {
        prayers: [],
        reminderMinutes: 30,
        pushEnabled: false,
      },
    })
    .select()
    .single();

  if (error) throw error;
  if (!data) throw new Error('Failed to create user profile');

  return {
    id: data.id,
    username: data.username,
    email: data.email,
    favorites: data.favorites,
    notificationPreferences: data.notification_preferences,
  };
}

export async function getUser(id: string): Promise<User | null> {
  const { data, error } = await supabase
    .from('users')
    .select()
    .eq('id', id)
    .single();

  if (error) return null;
  if (!data) return null;

  return {
    id: data.id,
    username: data.username,
    email: data.email,
    favorites: data.favorites,
    notificationPreferences: data.notification_preferences,
  };
}

export async function verifyUserPassword(email: string, password: string): Promise<User | null> {
  const { data: authData, error: authError } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (authError) return null;
  if (!authData.user) return null;

  const { data, error } = await supabase
    .from('users')
    .select()
    .eq('id', authData.user.id)
    .single();

  if (error || !data) return null;

  return {
    id: data.id,
    username: data.username,
    email: data.email,
    favorites: data.favorites,
    notificationPreferences: data.notification_preferences,
  };
}

export async function updateUser(id: string, data: Partial<Omit<User, 'id' | 'email'>>): Promise<User> {
  const { data: updatedData, error } = await supabase
    .from('users')
    .update({
      ...(data.username && { username: data.username }),
      ...(data.favorites && { favorites: data.favorites }),
      ...(data.notificationPreferences && { notification_preferences: data.notificationPreferences }),
    })
    .eq('id', id)
    .select()
    .single();

  if (error) throw error;
  if (!updatedData) throw new Error('Failed to update user');

  return {
    id: updatedData.id,
    username: updatedData.username,
    email: updatedData.email,
    favorites: updatedData.favorites,
    notificationPreferences: updatedData.notification_preferences,
  };
}

// Synagogues
export async function getSynagogues(): Promise<Synagogue[]> {
  const { data, error } = await supabase
    .from('synagogues')
    .select();

  if (error) throw error;
  if (!data) return [];

  return data.map(row => ({
    id: row.id,
    name: row.name,
    address: row.address,
    coordinates: row.coordinates,
    phone: row.phone || undefined,
    hasAccessCode: row.has_access_code,
    prayerTimes: row.prayer_times,
    rite: row.rite,
    description: row.description || undefined,
    imageUrl: row.image_url || undefined,
  }));
}