import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Navigation } from './Navigation';
import { Footer } from './shared/Footer';
import { LoadingPage } from './shared/LoadingPage';
import { Container } from './shared/Container';

export function Layout() {
  return (
    <div className="min-h-screen flex flex-col">
      <Navigation />
      <main className="flex-1 py-8 animate-fade-in">
        <Suspense fallback={<LoadingPage />}>
          <Container>
            <Outlet />
          </Container>
        </Suspense>
      </main>
      <Footer />
    </div>
  );
}