import React from 'react';
import { ContactForm } from '../components/contact/ContactForm';
import { PageHeader } from '../components/shared/PageHeader';
import { Container } from '../components/shared/Container';
import { usePageTitle } from '../hooks/usePageTitle';

export function Contact() {
  usePageTitle('Contact');

  return (
    <Container className="py-8">
      <PageHeader 
        title="Contact" 
        description="Une question ? N'hésitez pas à nous contacter" 
      />
      
      <div className="max-w-2xl mx-auto">
        <ContactForm />
      </div>
    </Container>
  );
}