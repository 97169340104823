import React from 'react';
import { PageHeader } from '../../components/shared/PageHeader';
import { Container } from '../../components/shared/Container';

export function Terms() {
  return (
    <Container className="py-8">
      <PageHeader 
        title="Conditions d'Utilisation" 
        description="Conditions générales d'utilisation de Tefilati" 
      />
      
      <div className="prose prose-blue max-w-none">
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Acceptation des conditions</h2>
          <p>En utilisant Tefilati, vous acceptez les présentes conditions d'utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre service.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Description du service</h2>
          <p>Tefilati est une application permettant de :</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Localiser les synagogues de Marseille</li>
            <li>Consulter les horaires des offices</li>
            <li>Recevoir des notifications de prière</li>
            <li>Gérer vos synagogues favorites</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Compte utilisateur</h2>
          <p>Pour utiliser certaines fonctionnalités, vous devez créer un compte. Vous êtes responsable de :</p>
          <ul className="list-disc pl-6 mb-4">
            <li>La confidentialité de votre mot de passe</li>
            <li>L'exactitude des informations fournies</li>
            <li>Toute activité sur votre compte</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Utilisation acceptable</h2>
          <p>Vous vous engagez à ne pas :</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Utiliser le service de manière frauduleuse</li>
            <li>Perturber le fonctionnement du service</li>
            <li>Collecter des informations sur les autres utilisateurs</li>
            <li>Violer les lois applicables</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">Modification des conditions</h2>
          <p>Nous nous réservons le droit de modifier ces conditions à tout moment. Les modifications entrent en vigueur dès leur publication. Votre utilisation continue du service après ces modifications constitue votre acceptation des nouvelles conditions.</p>
        </section>
      </div>
    </Container>
  );
}