import React, { useState } from 'react';
import { Send } from 'lucide-react';
import emailjs from '@emailjs/browser';
import { Input } from '../shared/Input';
import { Button } from '../shared/Button';
import { Alert } from '../shared/Alert';
import { useContactForm } from '../../hooks/useContactForm';

export function ContactForm() {
  const {
    formData,
    handleChange,
    handleSubmit,
    isLoading,
    error,
    success
  } = useContactForm();

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {error && (
        <Alert type="error">
          {error}
        </Alert>
      )}

      {success && (
        <Alert type="success">
          Votre message a été envoyé avec succès. Nous vous répondrons dans les plus brefs délais.
        </Alert>
      )}

      <Input
        label="Nom"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
      />

      <Input
        label="Email"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        required
      />

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Message
        </label>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          rows={6}
          className="w-full px-4 py-2.5 rounded-xl border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20"
        />
      </div>

      <Button
        type="submit"
        variant="gradient"
        isLoading={isLoading}
        className="w-full"
      >
        <Send className="w-4 h-4" />
        Envoyer
      </Button>
    </form>
  );
}