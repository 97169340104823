import React from 'react';
import { useStore } from '../store/useStore';
import { useQuery } from '@tanstack/react-query';
import { getSynagogues } from '../services/db';
import { SynagogueList } from '../components/schedule/SynagogueList';
import { EmptyState } from '../components/shared/EmptyState';
import { Heart } from 'lucide-react';
import { PageHeader } from '../components/shared/PageHeader';
import { LoadingPage } from '../components/shared/LoadingPage';

export function Favorites() {
  const { user } = useStore();
  const { data: synagogues, isLoading } = useQuery({
    queryKey: ['synagogues'],
    queryFn: getSynagogues
  });

  if (isLoading || !synagogues) {
    return <LoadingPage />;
  }

  const favoriteSynagogues = synagogues.filter((synagogue) =>
    user?.favorites.includes(synagogue.id)
  );

  if (favoriteSynagogues.length === 0) {
    return (
      <div className="max-w-4xl mx-auto">
        <PageHeader title="Mes Favoris" />
        <EmptyState
          icon={<Heart className="w-12 h-12" />}
          title="Aucun favori"
          description="Vous n'avez pas encore de synagogues favorites. Parcourez la liste des synagogues et cliquez sur le cœur pour les ajouter à vos favoris."
        />
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      <PageHeader title="Mes Favoris" />
      <SynagogueList synagogues={favoriteSynagogues} />
    </div>
  );
}