import React from 'react';
import { SynagogueList } from '../components/schedule/SynagogueList';
import { PrayerTimeFilter } from '../components/schedule/PrayerTimeFilter';
import { synagogues } from '../data/synagogues';

export function Schedule() {
  const [filters, setFilters] = React.useState({
    prayer: 'all',
    sortBy: 'distance',
    showOnlyAccessible: false,
    showNextOnly: false,
  });

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Horaires des Prières</h1>
      
      <PrayerTimeFilter filters={filters} onFilterChange={setFilters} />
      
      <div className="mt-6">
        <SynagogueList synagogues={synagogues} />
      </div>
    </div>
  );
}