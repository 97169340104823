import React from 'react';
import { PageHeader } from '../../components/shared/PageHeader';
import { Container } from '../../components/shared/Container';

export function LegalNotice() {
  return (
    <Container className="py-8">
      <PageHeader 
        title="Mentions Légales" 
        description="Informations légales concernant Tefilati" 
      />
      
      <div className="prose prose-blue max-w-none">
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Éditeur du site</h2>
          <p>Tefilati<br />
          13006 Marseille<br />
          France</p>
          <p>Email : contact@tefilati.com</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Hébergement</h2>
          <p>Ce site est hébergé par :<br />
          Firebase (Google LLC)<br />
          1600 Amphitheatre Parkway<br />
          Mountain View, CA 94043<br />
          États-Unis</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Propriété intellectuelle</h2>
          <p>L'ensemble du contenu de ce site (textes, images, vidéos, etc.) est protégé par le droit d'auteur. Toute reproduction, même partielle, est soumise à notre autorisation préalable.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Responsabilité</h2>
          <p>Les informations fournies sur ce site le sont à titre indicatif. Malgré tout le soin apporté à leur exactitude, des erreurs ou omissions peuvent survenir. L'utilisateur est seul responsable de l'utilisation de ces informations.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">Données personnelles</h2>
          <p>Conformément à la loi Informatique et Libertés du 6 janvier 1978, vous disposez d'un droit d'accès, de rectification et de suppression des données vous concernant. Pour l'exercer, veuillez nous contacter par email.</p>
        </section>
      </div>
    </Container>
  );
}