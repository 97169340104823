import React from 'react';
import { PageHeader } from '../../components/shared/PageHeader';
import { Container } from '../../components/shared/Container';

export function PrivacyPolicy() {
  return (
    <Container className="py-8">
      <PageHeader 
        title="Politique de Confidentialité" 
        description="Comment nous protégeons vos données" 
      />
      
      <div className="prose prose-blue max-w-none">
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Collecte des données</h2>
          <p>Nous collectons les informations suivantes :</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Nom d'utilisateur</li>
            <li>Adresse email</li>
            <li>Préférences de notification</li>
            <li>Synagogues favorites</li>
            <li>Données de géolocalisation (avec votre consentement)</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Utilisation des données</h2>
          <p>Vos données sont utilisées pour :</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Gérer votre compte</li>
            <li>Vous envoyer des notifications de prière</li>
            <li>Personnaliser votre expérience</li>
            <li>Améliorer nos services</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Protection des données</h2>
          <p>Nous mettons en œuvre des mesures de sécurité pour protéger vos informations :</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Chiffrement des données</li>
            <li>Authentification sécurisée</li>
            <li>Accès restreint aux données personnelles</li>
            <li>Hébergement sécurisé via Firebase</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Vos droits</h2>
          <p>Conformément au RGPD, vous disposez des droits suivants :</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Droit d'accès à vos données</li>
            <li>Droit de rectification</li>
            <li>Droit à l'effacement</li>
            <li>Droit à la portabilité</li>
            <li>Droit d'opposition</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">Contact</h2>
          <p>Pour toute question concernant vos données personnelles :</p>
          <p>Email : contact@tefilati.com</p>
        </section>
      </div>
    </Container>
  );
}