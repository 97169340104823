import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../store/useStore';
import { getUser, createUser, verifyUserPassword } from '../services/db';
import type { User } from '../types';

const AUTH_TOKEN_KEY = 'auth_token';

export function useAuth() {
  const [loading, setLoading] = useState(true);
  const { setUser } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    if (token) {
      loadUser(token);
    } else {
      setLoading(false);
    }
  }, [setUser]);

  const loadUser = async (userId: string) => {
    try {
      const userData = await getUser(userId);
      if (userData) {
        setUser(userData);
      } else {
        localStorage.removeItem(AUTH_TOKEN_KEY);
      }
    } catch (error) {
      console.error('Error loading user:', error);
      localStorage.removeItem(AUTH_TOKEN_KEY);
    } finally {
      setLoading(false);
    }
  };

  const signUp = async (email: string, password: string, username: string) => {
    try {
      const userData = {
        username,
        email,
        password,
        favorites: [],
        notificationPreferences: {
          prayers: [],
          reminderMinutes: 30,
          pushEnabled: false,
        },
      };

      const user = await createUser(userData);
      localStorage.setItem(AUTH_TOKEN_KEY, user.id);
      setUser(user);

      return { success: true, message: 'Compte créé avec succès' };
    } catch (error: any) {
      console.error('Error during sign up:', error);
      throw new Error(error?.message || 'Une erreur est survenue lors de l\'inscription');
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      const user = await verifyUserPassword(email, password);
      if (!user) {
        throw new Error('Email ou mot de passe incorrect');
      }

      localStorage.setItem(AUTH_TOKEN_KEY, user.id);
      setUser(user);
      return { success: true };
    } catch (error: any) {
      console.error('Error during sign in:', error);
      throw new Error(error?.message || 'Email ou mot de passe incorrect');
    }
  };

  const signOut = async () => {
    try {
      localStorage.removeItem(AUTH_TOKEN_KEY);
      setUser(null);
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Error during sign out:', error);
      throw error;
    }
  };

  return {
    loading,
    signUp,
    signIn,
    signOut,
  };
}