import { useEffect } from 'react';

export function usePageTitle(title: string) {
  useEffect(() => {
    const previousTitle = document.title;
    const fullTitle = `${title} | Tefilati - Guide des Synagogues de Marseille`;
    
    document.title = fullTitle;

    // Update meta tags
    const metaDescription = document.querySelector('meta[name="description"]');
    const ogTitle = document.querySelector('meta[property="og:title"]');
    const ogDescription = document.querySelector('meta[property="og:description"]');
    const twitterTitle = document.querySelector('meta[name="twitter:title"]');
    const twitterDescription = document.querySelector('meta[name="twitter:description"]');

    if (metaDescription) {
      metaDescription.setAttribute('content', `${title} - Tefilati, votre guide des synagogues de Marseille. Horaires des offices, notifications et localisation en temps réel.`);
    }

    if (ogTitle) {
      ogTitle.setAttribute('content', fullTitle);
    }

    if (ogDescription) {
      ogDescription.setAttribute('content', `${title} - Tefilati, votre guide des synagogues de Marseille. Horaires des offices, notifications et localisation en temps réel.`);
    }

    if (twitterTitle) {
      twitterTitle.setAttribute('content', fullTitle);
    }

    if (twitterDescription) {
      twitterDescription.setAttribute('content', `${title} - Tefilati, votre guide des synagogues de Marseille. Horaires des offices, notifications et localisation en temps réel.`);
    }

    return () => {
      document.title = previousTitle;
    };
  }, [title]);
}